<template>
  <div>
    <b-row>
      <b-col class="mt-2"
        ><h3>{{ fragetext }}</h3></b-col
      >
    </b-row>
    <b-row v-if="hinweistext">
      <b-col class="mb-2">{{ hinweistext }}</b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-textarea
          v-model="antwort"
          placeholder="Antwort:"
          rows="2"
          max-rows="4"
        ></b-form-textarea>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fragetext: "Was möchtest du an deiner Haut ändern oder verbessern?",
      hinweistext: undefined,
      antwort: "",
      xs: 12,
      sm: 12,
      md: 4,
      lg: 4,
      xl: 4,
    };
  },
  props: {
    Frage: { required: true },
  },
  watch: {
    antwort() {
      this.$store.commit("setAnwser", {
        Frage: this.Frage,
        Fragentext: this.fragetext,
        Antwort: this.antwort,
      });
    },
  },
  beforeMount() {
    const answ = this.$store.getters.getAnwsers;
    if (answ[this.Frage - 1]) {
      this.antwort = answ[this.Frage - 1].Antwort;
    }
  },
};
</script>